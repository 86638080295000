@import url(https://fonts.googleapis.com/css2?family=Lora:ital,wght@1,500;1,700&family=Roboto:wght@300;400&display=swap);
body {
  margin: 0;
  font-family: Roboto;
  font-weight: 300;
  font-size: 16px;
  padding-bottom: 2rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body {
  height: 100%;
}

.jumbo {
  background-color: #e2e0e0;
  background-image: url(/static/media/black-linen.8a1abc77.png);
}

.jumbo.container-fluid {
  margin: 0 !important;
  padding: 0;
}

.formContainer {
  padding-top: 2vw;
  color: whitesmoke;
  background-color: rgba(0, 0, 0, 0.5);
  background-image: url(/static/media/black-linen.8a1abc77.png);
}

.formContainer .header, h4 {
  font-family: "Lora", serif;
  text-align: center;
  margin-bottom: 2vw;
}

.formContainer hr {
  border-top: 0.1vw solid yellowgreen;
  border-radius: 20px;
  width: 50vw;
}

.formContainer a {
  color: yellowgreen;
  text-decoration: underline;
}
.formContainer a:hover {
  color: yellow;
  text-decoration: underline;
}

.HTMLPreview {
  margin-bottom: 2vw;
}

#root > div.formContainer.container-fluid {
  padding-bottom: 1vw;
}


.input {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  padding: 2vw 0 4vw;
  text-align: left;
}

.input .custom-file-label {
  border-color: yellowgreen;
  background-color: transparent;
  color: whitesmoke;
}

.emptyState {
  display: flex;
  justify-content: center;
}


.dataOverview {
  max-height: 20vw;
  overflow-y: auto;
  overflow-X: auto;
  border: 1px solid yellowgreen;
}

.dataOverview tr {
  border-bottom: 1px solid whitesmoke;
}

.dataOverview {
  --scrollbarBG: darkslategrey;
  --thumbBG: yellowgreen;
  display: flex;
  justify-content: center;
  margin-bottom: 3vw;
}

.dataOverviewSubtitle {
  font-family: "Lora", serif;
  text-align: center;
  margin-bottom: 2vw;
}

::-webkit-scrollbar {
  width: 1vw;
}

.dataOverview {
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}

::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}

::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG) ;
  border-radius: 10%;
  border: 0.4vw solid var(--scrollbarBG);
}

.welcome {
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  padding-left: min(20vw, 10%);
  padding-right: min(20vw, 10%);
  padding-top: min(5vw, 10%);
  padding-bottom: min(5vw, 10%);
  min-height: 100vh;
  font-size: min(5vw, 16pt);
}

.welcome a {
  text-decoration: underline;
  color: yellowgreen;
  font-weight: 500;
}

.welcome b {
  font-family: "Lora", serif;
}

.welcome h1 {
  font-size: min(20vw,165px);
  margin: min(4.5vh, 50px);
}

.welcome h1, h2 {
  font-family: "Lora", serif;
  text-align: center;
}

.welcome h5 {
  font-family: "Lora", serif;
  margin: 2rem 1rem 1.5rem -1rem;
  font-size: min(7vw, 20pt);
}

.welcome li {
  padding-bottom: min(1vw, 10px)
}

.welcome .btn.btn-primary {
  background-color: darkslategray;
  color: white;
  border: 0.1vh solid yellowgreen;
  border-radius: 100%;
  margin-right: max(10vw, 50px);
  position: fixed;
  bottom: 5vh;
  right: 0vh;
  font-weight: 600;
  width: max(min(15vw, 75px), 60px);
  height: max(min(15vw, 75px), 60px);
}

.welcome .btn.btn-primary:hover {
  background-color: yellowgreen;
  transition: ease-in-out 0.3s;
}

.welcome .cta {
  color: white !important;
  font-family: "Lora", serif;
}

.welcome .exclamationPoint {
  display:inline-block;
  transform:rotate(25deg);
  padding-right: 0.3rem;
  color:yellowgreen;
  font-family: "Lora", serif;
}

.welcome .pointer {
  cursor: pointer;
}

.welcome .flex {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.welcome .icon {
  font-size: max(min(4vw, 50px), 35px);
}

.welcome .list {
  list-style-type: none;
  padding-left: 0;
}

.welcome .slanted {
  display:inline-block;
  transform:rotate(25deg);
}

.welcome .subtitle {
  margin-right: max(10vw, 50px);
  float: right;
  font-size: min(3vw, 12pt);
}

.welcome .yellowgreen {
  color: yellowgreen;
  font-family: "Lora", serif;
}

.paragraphField {
  margin-top: 1rem;
}

.deletebutton {
  margin-bottom: 1rem;
  margin-top: 0.3rem;
}

.btn.btn-outline-danger.deletebutton.float-right.btn-sm {
  color: yellow;
  border-color: yellow;
}

small.text-muted.form-text {
    color: whitesmoke!important;
}
.btn.btn-custom-red {
    background-color: #FF0000;
    color: white;
}

.btn.btn-custom-blue {
    background-color: #2B5797;
    color: white;
}

.btn.btn-outline-primary {
    color: yellowgreen;
    border-color: yellowgreen;
}
.btn.btn-outline-primary:hover {
    color: whitesmoke;
    background-color: yellowgreen;
    border-color: yellowgreen;
}

.btn.btn-primary {
    background-color: yellowgreen;
    border-color: yellowgreen;
    color: whitesmoke
}

.btn.btn-primary:hover, .btn.btn-primary:focus, .btn-primary.focus, .btn.btn-primary:active, .btn.btn-primary:target {
    background-color: darkslategray;
    border-color: yellowgreen;
    color: whitesmoke
}

.form {
    padding: 0 10vw 0 10vw;
}

.form .exclamationPoint {
    display: inline-block;
  transform:rotate(25deg);
  padding-right: 0.3rem;
  color:yellowgreen;
  font-family: "Lora", serif;
}

.form .listo {
    font-family: "Lora", serif;
}

.buttongroup.btn-group {
    display: flex;
    margin: 2vw 10vw 2vw 10vw;
}


.preview {
    font-family: 'Times New Roman', Times, serif;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    padding: 2rem;
    margin-top: 2rem;
}
.overlay {
    color: white;
    background-color: rgba(0, 0, 0, 1);
    font-size: min(5vw, 16pt);
    position: fixed;
    z-index: 3;
    max-width: 80%;
    min-height: 60%;
    top: 10%;
    left: 10%;
    right: 10%;
    bottom: 90%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
  }
  
  .overlay a {
    text-decoration: underline;
    color: yellowgreen;
    font-weight: 500;

  }
  
  .overlay b {
    font-family: "Lora", serif;
  }
  
  .overlay h1 {
    font-size: min(20vw,165px);
    margin: min(4.5vh, 50px);
  }
  
  .overlay h1, h2 {
    font-family: "Lora", serif;
    text-align: center;
  }
  
  .overlay h5 {
    font-family: "Lora", serif;
    margin: 2rem 1rem 1.5rem -1rem;
    font-size: min(7vw, 20pt);
  }
  
  .overlay li {
    padding-bottom: min(1vw, 10px)
  }
  
  .overlay .btn.btn-primary {
    background-color: darkslategray;
    color: white;
    border: 0.1vh solid yellowgreen;
    border-radius: 100%;
    margin-right: max(10vw, 50px);
    position: fixed;
    bottom: 5vh;
    right: 0vh;
    font-weight: 600;
    width: max(min(15vw, 75px), 60px);
    height: max(min(15vw, 75px), 60px);
  }
  
  .overlay .btn.btn-primary:hover {
    background-color: yellowgreen;
    transition: ease-in-out 0.3s;
  }
  
  .overlay .cta {
    color: white !important;
    font-family: "Lora", serif;
  }
  
  .overlay .exclamationPoint {
    display:inline-block;
    transform:rotate(25deg);
    padding-right: 0.3rem;
    color:yellowgreen;
    font-family: "Lora", serif;
  }
  
  .overlay .flex {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  
  .overlay .icon {
    font-size: max(min(4vw, 50px), 35px);
  }
  
  .overlay .list {
    list-style-type: none;
    padding-left: 0;
  }
  
  .overlay .slanted {
    display:inline-block;
    transform:rotate(25deg);
  }
  
  .overlay .subtitle {
    margin-right: max(10vw, 50px);
    float: right;
    font-size: min(3vw, 12pt);
  }
  
  .overlay .yellowgreen {
    color: yellowgreen;
    font-family: "Lora", serif;
  }

.overlay a {
    cursor: pointer;
    margin: 2vh 0 2vh 0;
    text-decoration: none;
}

.overlay svg {
    margin-right: 2vh;
    margin-bottom: 0.4vh;
}
  
