.input {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  padding: 2vw 0 4vw;
  text-align: left;
}

.input .custom-file-label {
  border-color: yellowgreen;
  background-color: transparent;
  color: whitesmoke;
}

.emptyState {
  display: flex;
  justify-content: center;
}