html, body {
  height: 100%;
}

.jumbo {
  background-color: #e2e0e0;
  background-image: url(./black-linen.png);
}

.jumbo.container-fluid {
  margin: 0 !important;
  padding: 0;
}

.formContainer {
  padding-top: 2vw;
  color: whitesmoke;
  background-color: rgba(0, 0, 0, 0.5);
  background-image: url(./black-linen.png);
}

.formContainer .header, h4 {
  font-family: "Lora", serif;
  text-align: center;
  margin-bottom: 2vw;
}

.formContainer hr {
  border-top: 0.1vw solid yellowgreen;
  border-radius: 20px;
  width: 50vw;
}

.formContainer a {
  color: yellowgreen;
  text-decoration: underline;
}
.formContainer a:hover {
  color: yellow;
  text-decoration: underline;
}

.HTMLPreview {
  margin-bottom: 2vw;
}

#root > div.formContainer.container-fluid {
  padding-bottom: 1vw;
}

