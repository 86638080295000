.welcome {
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  padding-left: min(20vw, 10%);
  padding-right: min(20vw, 10%);
  padding-top: min(5vw, 10%);
  padding-bottom: min(5vw, 10%);
  min-height: 100vh;
  font-size: min(5vw, 16pt);
}

.welcome a {
  text-decoration: underline;
  color: yellowgreen;
  font-weight: 500;
}

.welcome b {
  font-family: "Lora", serif;
}

.welcome h1 {
  font-size: min(20vw,165px);
  margin: min(4.5vh, 50px);
}

.welcome h1, h2 {
  font-family: "Lora", serif;
  text-align: center;
}

.welcome h5 {
  font-family: "Lora", serif;
  margin: 2rem 1rem 1.5rem -1rem;
  font-size: min(7vw, 20pt);
}

.welcome li {
  padding-bottom: min(1vw, 10px)
}

.welcome .btn.btn-primary {
  background-color: darkslategray;
  color: white;
  border: 0.1vh solid yellowgreen;
  border-radius: 100%;
  margin-right: max(10vw, 50px);
  position: fixed;
  bottom: 5vh;
  right: 0vh;
  font-weight: 600;
  width: max(min(15vw, 75px), 60px);
  height: max(min(15vw, 75px), 60px);
}

.welcome .btn.btn-primary:hover {
  background-color: yellowgreen;
  transition: ease-in-out 0.3s;
}

.welcome .cta {
  color: white !important;
  font-family: "Lora", serif;
}

.welcome .exclamationPoint {
  display:inline-block;
  transform:rotate(25deg);
  padding-right: 0.3rem;
  color:yellowgreen;
  font-family: "Lora", serif;
}

.welcome .pointer {
  cursor: pointer;
}

.welcome .flex {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.welcome .icon {
  font-size: max(min(4vw, 50px), 35px);
}

.welcome .list {
  list-style-type: none;
  padding-left: 0;
}

.welcome .slanted {
  display:inline-block;
  transform:rotate(25deg);
}

.welcome .subtitle {
  margin-right: max(10vw, 50px);
  float: right;
  font-size: min(3vw, 12pt);
}

.welcome .yellowgreen {
  color: yellowgreen;
  font-family: "Lora", serif;
}
