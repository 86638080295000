.overlay {
    color: white;
    background-color: rgba(0, 0, 0, 1);
    font-size: min(5vw, 16pt);
    position: fixed;
    z-index: 3;
    max-width: 80%;
    min-height: 60%;
    top: 10%;
    left: 10%;
    right: 10%;
    bottom: 90%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
  }
  
  .overlay a {
    text-decoration: underline;
    color: yellowgreen;
    font-weight: 500;

  }
  
  .overlay b {
    font-family: "Lora", serif;
  }
  
  .overlay h1 {
    font-size: min(20vw,165px);
    margin: min(4.5vh, 50px);
  }
  
  .overlay h1, h2 {
    font-family: "Lora", serif;
    text-align: center;
  }
  
  .overlay h5 {
    font-family: "Lora", serif;
    margin: 2rem 1rem 1.5rem -1rem;
    font-size: min(7vw, 20pt);
  }
  
  .overlay li {
    padding-bottom: min(1vw, 10px)
  }
  
  .overlay .btn.btn-primary {
    background-color: darkslategray;
    color: white;
    border: 0.1vh solid yellowgreen;
    border-radius: 100%;
    margin-right: max(10vw, 50px);
    position: fixed;
    bottom: 5vh;
    right: 0vh;
    font-weight: 600;
    width: max(min(15vw, 75px), 60px);
    height: max(min(15vw, 75px), 60px);
  }
  
  .overlay .btn.btn-primary:hover {
    background-color: yellowgreen;
    transition: ease-in-out 0.3s;
  }
  
  .overlay .cta {
    color: white !important;
    font-family: "Lora", serif;
  }
  
  .overlay .exclamationPoint {
    display:inline-block;
    transform:rotate(25deg);
    padding-right: 0.3rem;
    color:yellowgreen;
    font-family: "Lora", serif;
  }
  
  .overlay .flex {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  
  .overlay .icon {
    font-size: max(min(4vw, 50px), 35px);
  }
  
  .overlay .list {
    list-style-type: none;
    padding-left: 0;
  }
  
  .overlay .slanted {
    display:inline-block;
    transform:rotate(25deg);
  }
  
  .overlay .subtitle {
    margin-right: max(10vw, 50px);
    float: right;
    font-size: min(3vw, 12pt);
  }
  
  .overlay .yellowgreen {
    color: yellowgreen;
    font-family: "Lora", serif;
  }

.overlay a {
    cursor: pointer;
    margin: 2vh 0 2vh 0;
    text-decoration: none;
}

.overlay svg {
    margin-right: 2vh;
    margin-bottom: 0.4vh;
}
  