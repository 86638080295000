small.text-muted.form-text {
    color: whitesmoke!important;
}
.btn.btn-custom-red {
    background-color: #FF0000;
    color: white;
}

.btn.btn-custom-blue {
    background-color: #2B5797;
    color: white;
}

.btn.btn-outline-primary {
    color: yellowgreen;
    border-color: yellowgreen;
}
.btn.btn-outline-primary:hover {
    color: whitesmoke;
    background-color: yellowgreen;
    border-color: yellowgreen;
}

.btn.btn-primary {
    background-color: yellowgreen;
    border-color: yellowgreen;
    color: whitesmoke
}

.btn.btn-primary:hover, .btn.btn-primary:focus, .btn-primary.focus, .btn.btn-primary:active, .btn.btn-primary:target {
    background-color: darkslategray;
    border-color: yellowgreen;
    color: whitesmoke
}

.form {
    padding: 0 10vw 0 10vw;
}

.form .exclamationPoint {
    display: inline-block;
  transform:rotate(25deg);
  padding-right: 0.3rem;
  color:yellowgreen;
  font-family: "Lora", serif;
}

.form .listo {
    font-family: "Lora", serif;
}

.buttongroup.btn-group {
    display: flex;
    margin: 2vw 10vw 2vw 10vw;
}

