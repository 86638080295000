.paragraphField {
  margin-top: 1rem;
}

.deletebutton {
  margin-bottom: 1rem;
  margin-top: 0.3rem;
}

.btn.btn-outline-danger.deletebutton.float-right.btn-sm {
  color: yellow;
  border-color: yellow;
}
