.dataOverview {
  max-height: 20vw;
  overflow-y: auto;
  overflow-X: auto;
  border: 1px solid yellowgreen;
}

.dataOverview tr {
  border-bottom: 1px solid whitesmoke;
}

.dataOverview {
  --scrollbarBG: darkslategrey;
  --thumbBG: yellowgreen;
  display: flex;
  justify-content: center;
  margin-bottom: 3vw;
}

.dataOverviewSubtitle {
  font-family: "Lora", serif;
  text-align: center;
  margin-bottom: 2vw;
}

::-webkit-scrollbar {
  width: 1vw;
}

.dataOverview {
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}

::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}

::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG) ;
  border-radius: 10%;
  border: 0.4vw solid var(--scrollbarBG);
}
